export default [
  {
    id: 1,
    contacts: [
      {
        company: "Abatement Technologies",
        name: "Brian Strawn",
        phone: "1-800-827-6443",
        fax: "905-871-8291",
        email: "bstrawn@abatement.ca",
        address: "1481 Harber Ct.",
        city: "Fort Erie",
        province: "Ontario",
        postal: "L2A 0G3",
        website: "www.abatement.ca",
        latitude: 42.92455613722816,
        longitude: -78.9715742711647,
      },
      {
        company: "Abatement Technologies",
        name: "Trevor Myers",
        phone: "778-372-2611",
        fax: "",
        email: "tmyers@abatement.ca",
        address: "F102-19720-94A Avenue",
        city: "Langley",
        province: "British Columbia",
        postal: "V1M 3B7",
        website: "www.abatement.ca",
        latitude: 49.173194340079256,
        longitude: -122.67676932690178,
      },
      {
        company: "Abatement Technologies",
        name: "Larry Gibbons",
        phone: "902-334-0900",
        fax: "",
        email: "lgibbons@abatement.ca",
        address: "121 Ilsley Ave., Unit 12",
        city: "Dartmouth",
        province: "Nova Scotia",
        postal: "B3B 1J1",
        website: "www.abatement.ca",
        latitude: 44.697597671065346,
        longitude: -63.58402602927443,
      },
      {
        company: "Abatement Technologies",
        name: "Chad Strawn",
        phone: "905-266-1322",
        fax: "",
        email: "chad@abatement.ca",
        address: "625 Rowntree Dairy Rd., Unit 1",
        city: "Vaughan",
        province: "Ontario",
        postal: "L4L 5T9",
        website: "www.abatement.ca",
        latitude: 43.77868312468785,
        longitude: -79.56550814559081,
      },
    ],
    logo: "/images/logos/general/abatement-technologies.png",
    profile: [
      "Abatement Technologies® powerful line of high-quality HEPA-AIRE® Negative Air Machines and specially-formulated, environmentally-sensitive chemicals help contractors perform more effectively and efficiently. With over 25 years of design and engineering experience, Abatement Technologies has developed a reputation for industry-leading innovations that are second to none. Learn how our state-of-the-art abatement products can increase contractor productivity and profits.",
    ],
  },
  {
    id: 2,
    contacts: [
      {
        company: "Acute Environmental & Safety Services Inc.",
        name: "Ron Campbell",
        phone: "519-747-5075",
        fax: "519-757-4608",
        email: "info@acuteservices.com",
        address: "730 Bridge Street, #3",
        city: "Waterloo",
        province: "Ontario",
        postal: "N2V 2J4",
        website: "www.acuteservices.com",
        latitude: 43.51238303600744,
        longitude: -80.52187184521833,
      },
    ],
    logo: "/images/logos/general/acute.png",
    profile: [
      "ACUTE is a privately owned company providing training in Asbestos Abatement Type 1&2, Asbestos Abatement Worker, Asbestos Abatement Supervisor, as well as 14 approved IICRC courses fulfilling workers required needs. ACUTE also provides on-site services (rescue) for industrial, commercial, institutional, and governmental branches. Since 1996, ACUTE has been located in Waterloo.",
    ],
  },
  {
    id: 3,
    contacts: [
      {
        company: "All Clear Environmental",
        name: "Sidnei Casimiro",
        phone: "519-575-9033",
        fax: "",
        email: "sidnei@allclearenvironmental.ca",
        address: "12 – 262 Mill St",
        city: "Kitchener",
        province: "Ontario",
        postal: "N2M 3R6",
        website: "www.allclearenvironmental.ca",
        latitude: 43.444172024530246,
        longitude: -80.49445468387208,
      },
    ],
    logo: "/images/logos/general/all-clear.png",
    profile: [
      "All Clear Environmental is a fully licensed and insured company working in the Southwesternn Ontario area, providing abatement services to residential, commercial, institutional and industrial clients.",

      " Offering asbestos (Type 1, 2 and 3 operations), lead abatement (Type 1, 2 and 3 operations), mould remediation (Type 1, 2 and 3 operations), and infection control.",

      "All Clear Environmental is committed to provide the best quality service at affordable prices. Following O. Reg. 278/05, EACO mold and lead paint abatement guidelines.",
    ],
  },
  {
    id: 5,
    contacts: [
      {
        company: "Bi View Building Service Ltd.",
        name: "Jim Tran",
        phone: "905-712-1831",
        fax: "905-712-1830",
        email: "jim@biview.ca",
        address: "5228 Everest Drive",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4W 5C5",
        website: "www.biview.ca",
        latitude: 43.629225728944256,
        longitude: -79.6394922144697,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 5.1,
    contacts: [
      {
        company: "BuildingFixr Inc.",
        name: "Robert Costantin",
        phone: "226-346-4358",
        fax: "",
        email: "ecs.estimates@gmail.com",
        address: "2291 Hall Ave",
        city: "Windsor",
        province: "Ontario",
        postal: "N8W 2L8",
        website: "",
        latitude: 42.29606917175394,
        longitude: -83.00358197116373,
      },
    ],
    logo: "/images/logos/general/bf.jpg",
    profile: [],
    custom: (
      <>
        <h5 className="members-component__list-header">
          Maintenance Contracting / Asbestos Abatement / Demolition / Mould
          Remediation / Odor Control / Water Damage / Thermal Imaging
        </h5>
        <p className="members-component__text members-component__text--padding">
          BuildingFixr Inc. / General Contractor provides a wide range of
          construction services with our office centrally located in Essex
          County.
        </p>
        <p className="members-component__text members-component__text--padding">
          BuildingFixr Inc. has 45 years of experience working with commercial
          property management companies, school boards and insurance carriers as
          a preferred vendor throughout southwestern Ontario. Our Environmental
          services include the following:
        </p>
        <ul className="members-component__list">
          <li>Asbestos Abatement / Removal</li>
          <li>Mould Remediation / Removal</li>
          <li>Mould Sampling / Air Sampling for IAQ Investigations</li>
          <li>Odour Removal</li>
        </ul>
      </>
    ),
  },
  {
    id: 6,
    contacts: [
      {
        company: "Chatfield Technical Consulting Ltd.",
        name: "Dr. Eric J. Chatfield",
        phone: "905-896-7611",
        fax: "905-896-1930",
        email: "echatfield@ejchatfield.com",
        address: "2071 Dickson Road",
        city: "Mississauga",
        province: "Ontario",
        postal: "L5B 1Y8",
        website: "",
        latitude: 43.56161485171518,
        longitude: -79.61090394519378,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 7,
    contacts: [
      {
        company: "Coast to Coast Environmental Inc.",
        name: "Frank Dodaro",
        phone: "416-789-7575",
        fax: "416-789-9305",
        email: "ctcenviro@rogers.com",
        address: "15 Schell Ave",
        city: "Toronto",
        province: "Ontario",
        postal: "M6E 2S7",
        website: "www.ctcenviro.com",
        latitude: 43.69852160185716,
        longitude: -79.45238511443503,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 9,
    contacts: [
      {
        company: "Element Environmental Contracting",
        name: "Chris Richardson",
        phone: "416-660-9152",
        fax: "",
        email: "chris@element-env.ca",
        address: "P.O. Box 374",
        city: "Lefroy",
        province: "Ontario",
        postal: "L0L 1W0",
        website: "www.elementenvironmental.ca",
        latitude: "",
        longitude: "",
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 10,
    contacts: [
      {
        company: "Elite Environmental Group Inc.",
        name: "Jason Earle",
        phone: "613-695-2677",
        fax: "613-695-2477",
        email: "jason@eliteenvironmentalgroup.ca",
        address: "67 Jamie Avenue",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2E 7Y6",
        website: "www.eliteenvironmentalgroup.ca",
        latitude: 45.33625262534328,
        longitude: -75.71722507113763,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 11,
    contacts: [
      {
        company: "EMC Scientific Inc.",
        name: "Alister Haddad",
        phone: "905-629-9247",
        fax: "905-629-2607",
        email: "ahaddad@emcscientific.ca",
        address: "5800 Ambler Drive, #100",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4W 4J4",
        website: "www.emcscientific.ca",
        latitude: 43.64291639829408,
        longitude: -79.64579002980783,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 12,
    contacts: [
      {
        company: "EMSL Canada, Inc.",
        name: "Sarah Jamieson",
        phone: "289-207-0205",
        fax: "289-997-4607",
        email: "sjamieson@emsl.com",
        address: "2756 Slough Street",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4T 1G3",
        website: "www.emsl.com",
        latitude: 43.708260688025725,
        longitude: -79.6589958604653,
      },
      {
        company: "EMSL Canada, Inc.",
        name: "Sarah Jamieson",
        phone: "289-271-4362",
        fax: "",
        email: "markhamlab@emsl.com",
        address: "20 Amber Street, Unit #16",
        city: "Markham",
        province: "Ontario",
        postal: "L3R 5P4",
        website: "www.emsl.com",
        latitude: 43.82939108457462,
        longitude: -79.32948322971434,
      },
      {
        company: "EMSL Canada, Inc.",
        name: "Sarah Jamieson",
        phone: "343-882-6076",
        fax: "",
        email: "ottawalab@emsl.com",
        address: "Suite 102, 22 Antares Drive",
        city: "Ottawa",
        province: "Ontario",
        postal: "K2E 7Z6",
        website: "www.emsl.com",
        latitude: 45.334200802047214,
        longitude: -75.70882597498282,
      },
      {
        company: "EMSL Canada, Inc.",
        name: "Sarah Jamieson",
        phone: "403-879-1149",
        fax: "",
        email: "calgarylab@emsl.com",
        address: "2333 18th Avenue NE, Unit 48",
        city: "Calgary",
        province: "Alberta",
        postal: "T2E 8T6",
        website: "www.emsl.com",
        latitude: 51.06883224046817,
        longitude: -114.00462814119122,
      },
    ],
    logo: "/images/logos/general/emsl.png",
    services: "Asbestos, Lead, Mould, Vermiculite",
    profile: [
      "We offer analytical testing services to support environmental investigations focused on asbestos, microbiology, lead paint, environmental chemistry, indoor air quality, industrial hygiene and food testing. We also provide materials testing, characterization, and forensic laboratory services for a vast range of commercial, industrial, regulatory, and law enforcement clients. EMSL Products is poised and ready to help you with all of your sampling needs on your next project!",
    ],
  },
  {
    id: "First Response",
    contacts: [
      {
        company: "First Response Environmental 2012 Inc.",
        name: "Grace Dattomo",
        phone: "1-866-774-5501",
        fax: "",
        email: "grace@first-er.com",
        address: "1650 Upper Ottawa Street",
        city: "Hamilton",
        province: "Ontario",
        postal: "L8W 3P2",
        website: "www.first-er.com",
        latitude: 43.188674355123105,
        longitude: -79.84547927113863,
      },
    ],
    logo: "/images/logos/general/firstresponse.png",
    profile: [],
  },
  {
    id: 13,
    contacts: [
      {
        company: "Firstbrook, Cassie & Anderson Ltd.",
        name: "Warren Griffiths",
        phone: "416-629-7692",
        fax: "416-486-7035",
        email: "wgriffiths@fcainsurance.com",
        address: "1867 Yonge Street, Suite 300",
        city: "Toronto",
        province: "Ontario",
        postal: "M4S 1Y5",
        website: "www.fcainsurance.com",
        latitude: 43.69731062149739,
        longitude: -79.39585659909051,
      },
    ],
    logo: "/images/logos/general/fca-insurance.png",
    profile: [
      "FCA Insurance has been an active member of EACC for since 2006 and has a true understanding of the unique insurance and bonding needs of abatement contractors.",

      "FCA offers a customized insurance program for EACC contractors that offers best in class coverages at very reasonable rates.",

      "FCA proudly works with the majority of EACC contractors.",
    ],
  },
  {
    id: 14,
    contacts: [
      {
        company: "FPR Environmental Inc.",
        name: "Frank Parronchi",
        phone: "1-855-868-8222",
        fax: "519-568-8426",
        email: "info@asbestosmouldexperts.com",
        address: "5 – 11 Centennial Road",
        city: "Kitchener",
        province: "Ontario",
        postal: "N2B 3E9",
        website: "www.asbestosmouldexperts.com",
        latitude: 43.77472651675358,
        longitude: -79.14515262974183,
      },
    ],
    services: "Asbestos, Lead, Mould, Vermiculite",
    logo: "/images/logos/general/fpr.png",
    profile: [
      "FPR Environmental, a fully licensed and insured company working in the Southern Ontario area, providing abatement services to residential, commercial, institutional and industrial clients for over 22 years.",

      "Offering asbestos, lead abatement, mould remediation, and infection control.",

      "FPR is committed to the highest quality standards for all of our services at the most competitive rates.",
    ],
  },

  {
    id: 15,
    contacts: [
      {
        company: "H&S Specialties Inc.",
        name: "Ian Henderson",
        phone: "416-556-5893",
        fax: "",
        email: "ihenderson@hsspecialties.com",
        address: "1060 Salk Road, Unit 5",
        city: "Pickering",
        province: "Ontario",
        postal: "L1W 3C5",
        website: "www.hsspecialties.com",
        latitude: 43.83603825753053,
        longitude: -79.07212701436599,
      },
    ],
    logo: "/images/logos/general/hs.png",
    profile: [
      "H&S is proud to be a Canadian, privately owned and operated Health & Safety provider!",

      "H&S Specialties Inc. is the next generation of Health & Safety service, sales and support for environmental and industrial workplace hazards, providing support across Canada.",

      "At H&S Specialties, YOUR Health & Safety Is OUR Specialty!",
    ],
  },
  {
    id: 16,
    contacts: [
      {
        company: "Hazmasters Inc.",
        name: "Matt Nichols",
        phone: "905-427-0220",
        fax: "905-427-9901",
        email: "matt.nichols@hazmasters.com",
        address: "651 Hardwood Ave. North, Unit 4",
        city: "Ajax",
        province: "Ontario",
        postal: "L1Z 0K4",
        website: "www.hazmasters.com",
        latitude: 43.8741293466867,
        longitude: -79.0238676143468,
      },
    ],
    logo: "/images/logos/general/hazmasters.png",
    profile: [],
    custom: (
      <p className="members-component__text">
        Health & Safety Products, Education, Training and Services <br />{" "}
        HazSafeID: product inspection, identification and tracking <br />{" "}
        HazSafeED: safety training & education <br /> Site evaluations,
        equipment rental & leasing, product repair & maintenance, respiratory
        fit testing, DOP testing on site <br />
        95% of product orders are delivered in 48 hours or less, 24/7 emergency
        after hours service. 11 locations.
      </p>
    ),
  },
  {
    id: "icon",
    contacts: [
      {
        company: "Icon Restoration Services Inc.",
        name: "Elvis Selamaj",
        phone: "416-901-4266",
        fax: "",
        email: "elvis@iconrestoration.com",
        address: "245 Marlee Ave",
        city: "Toronto",
        province: "Ontario",
        postal: "M6B 4B8",
        website: "www.iconrestoration.com",
        latitude: 43.70574663377967,
        longitude: -79.4421168288359,
      },
    ],
    logo: "/images/logos/general/icon1.png",
    profile: [
      "Icon Restoration has provided customers in Toronto with fast and thorough environmental services for more than 20 year.",

      "We are fully specialized and equipped to handle any type of commercial and residential asbestos removal. Dealing with emergency situations for more than two decades has given us the necessary knowledge and experience to perfectly handle any type of mold removal process. ",

      "We are a specialized air inspection company that offers services for indoor air quality, mold testing, environmental assessments, and commercial buildings or office assessment in Toronto and the GTA",
    ],
  },
  {
    id: "ICP",
    contacts: [
      {
        company: "ICP – Fiberlock",
        name: "Mark Robertson",
        phone: "647-290-7878",
        fax: "",
        email: "mrobertson@icpgroup.com",
        address: "150 Dascomb Road",
        city: "Andover",
        province: "Massachusetts",
        postal: "US 01810",
        website: "www.icpgroup.com",
        latitude: 42.61977690939697,
        longitude: -71.17859402024595,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 16.1,
    contacts: [
      {
        company: "JoBi Construction",
        name: "Joe Narciso",
        phone: "519-227-1181",
        fax: "519-227-2078",
        email: "info@jobiconstruction.com",
        address: "33418 Roman Line",
        city: "Lucan",
        province: "Ontario",
        postal: "N0M 2J0",
        website: "www.jobiconstruction.com",
        latitude: 43.16401720239047,
        longitude: -81.36686967116394,
      },
    ],
    logo: "/images/logos/general/jobi.png",
    profile: [
      "JoBi Construction Ltd. was established in 2003 as a demolition and abatement company, which expanded over the years into one of the top industry leaders in southwestern Ontario.",

      "Our goal has always been to ensure the safest handling and disposal of hazardous materials. Throughout the years JoBi has continued to expand its services and client base.",

      "We pride ourselves in the completion of projects, done with the utmost precision and expertise, as we strive to surpass expectations of health and safety, scheduling, and productivity. Not only have we been involved on award-winning job sites, but they have also been recognized in the community for sponsorship of local sports teams, and participation in multiple fundraisers and community events.",
    ],
  },
  {
    id: 18,
    contacts: [
      {
        company: "JPS Canada Air Systems Inc.",
        name: "Jean Moniz",
        phone: "416-895-7576",
        fax: "",
        email: "jp@jpscanada.com",
        address: "2599 Eglinton Ave W.",
        city: "Toronto",
        province: "Ontario",
        postal: "M6M 1T3",
        website: "www.jpscanada.com",
        latitude: 43.69022859180506,
        longitude: -79.47405891443917,
      },
    ],
    logo: "/images/logos/general/jps.png",
    profile: [
      "Since 2004, JPS Canada Air Systems Inc. has been addressing aging and contaminated Fan Coil systems in residential high rise buildings. JPS is a leading specialist in remediating Fan Coils by retrofitting, replacement and service. Our staff are fully trained in mould and asbestos abatement, and certified on cleaning and disinfection of viral pathogens. JPS provides maintenance, service, parts and replacement of all Fan Coil models.",
    ],
  },
  {
    id: 18.5,
    contacts: [
      {
        company: "Kinetic Environmental Ltd.",
        name: "Jeremy Robinson",
        phone: "250-381-6331",
        fax: "250-386-2286",
        email: "jrobinson@kineticenv.com",
        address: "301 - 740 Hillside Avenue",
        city: "Victoria",
        province: "British Columbia",
        postal: "V8T 1Z4",
        website: "www.kineticconstruction.com",
        latitude: 48.43895834827442,
        longitude: -123.36554932239741,
      },
      {
        company: "Kinetic Environmental Ltd.",
        name: "",
        phone: "604-284-5003",
        fax: "604-284-5004",
        email: "",
        address: "300 - 10851 Shellbridge Way",
        city: "Richmond",
        province: "British Columbia",
        postal: "V6X 2W8",
        website: "www.kineticconstruction.com",
        latitude: 49.18773701369338,
        longitude: -123.10556647617224,
      },
      {
        company: "Kinetic Environmental Ltd.",
        name: "",
        phone: "250-871-8662",
        fax: "250-871-8812",
        email: "",
        address: "210 - 2960 Moray Avenue",
        city: "Courtenay",
        province: "British Columbia",
        postal: "V9N 7S7",
        website: "www.kineticconstruction.com",
        latitude: 49.66896013209253,
        longitude: -124.98328328052366,
      },
    ],
    logo: "/images/logos/general/kel.png",
    profile: [
      "Kinetic Environmental Ltd. (KEL) is a fully integrated hazardous materials abatement and demolition contractor with offices in Victoria, Vancouver, and Courtenay.",

      "We offer a variety of delivery models and specializes in the safe and efficient removal of a wide range of pollutants from industrial, commercial, and institutional-built environments for public and private sector clients across Western and Northern Canada.",

      "Our mission is to create a healthier and more environmentally sustainable future by abating and remediating the dangers imposed by unsafe legacy construction materials and practices.",

      "We are fully supported by the experience and expertise of our parent company Kinetic Construction; and our proud to offer our integrated project delivery process that is proven to deliver more value to our clients.",
    ],
  },
  {
    id: 19,
    contacts: [
      {
        company: "Kleen Condition",
        name: "Irek Rogula",
        phone: "416-919-2093",
        fax: "888-415-0611",
        email: "irek@kleencondition.ca",
        address: "41 - 2601 Matheson Blvd E.",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4W 5A8",
        website: "www.kleencondition.com",
        latitude: 43.65979721876353,
        longitude: -79.60097521445434,
      },
    ],
    services: "Asbestos, Lead, Vermiculite",
    logo: "/images/logos/general/kleen-condition.png",
    profile: [
      "Kleen Condition is recognized as an expert in hazardous removal, we have been in business since 1989, successfully completing over four thousands projects of various size and complexities. We are noted for our professionalism and cost effectiveness.",
      "Our clients are from private and government sectors; property managers, businesses and all levels of government.",

      "Our projects range in scope, we are equipped to deal with all types of asbestos or mold remediation, and we size our repair crews accordingly. If needed, Kleen Condition can provide larger and/or multiple crews.",

      "Kleen Condition staff are trained to perform type 1, 2 and 3 asbestos abatement and level 1, 2 and 3 mold remediation (IICRC). We are certified as follows: Working at heights, WHMIS, fall protection, aerial lift training, etc. We provide special communication and sensitivity training for all employees working with tenants/owners in multi-unit buildings.",

      "We carry $5m general liability insurance and $5m environmental insurance coverage.",
    ],
  },
  {
    id: 22,
    contacts: [
      {
        company: "Matro Envi Solutions / GTA EG",
        name: "Abbas Obeid",
        phone: "416-556-3846",
        fax: "",
        email: "a.obeid@matro.ca",
        address: "200 – 55 Village Centre Place",
        city: "Mississauga",
        province: "Ontario",
        postal: "L4Z 1V9",
        website: "www.gtaeg.ca",
        latitude: 43.600306321852514,
        longitude: -79.64182852982925,
      },
    ],
    logo: "/images/logos/general/matro.png",
    profile: [
      "M.E.S is a fully licensed, insured, and bonded company established in 2011 to be working in Ontario, providing abatement and remediation services to residential, commercial, institutional and industrial clients.",

      "Offering asbestos abatement (Type I, II and III), lead abatement (Level I, II and III operations), mould remediation (Level I, II and III ).",

      "M.E.S is committed to provide the best quality service at affordable prices. Following O. Reg. 278/05, EACO mold and lead paint abatement guidelines.",
    ],
  },
  {
    id: "MFE",
    contacts: [
      {
        company: "MFE Inspection Solutions",
        name: "Aaron Hunter",
        phone: "647-560-0003",
        fax: "",
        email: "ahunter@mfe-is.com",
        address: "2840 Argentia Road, Unit 6",
        city: "Mississauga",
        province: "ON",
        postal: "L5N 8G4",
        website: "www.mfe-is.com",
        latitude: 43.59923001994818,
        longitude: -79.7743946800548,
      },
    ],
    logo: "/images/logos/general/mfe.png",
    profile: [
      "MFE Inspection Solutions is a leading NDT, RVI, Environmental, and UAV solutions provider. MFE Specialists partner with their customers to provide comprehensive support from making the initial appropriate equipment choices to implementation and assessment. MFE offers a large inventory of equipment from top manufacturers including Skydio, Flyability, and Boston Dynamics ensuring their customers have access to the most advanced, innovative technology available. MFE’s offerings expand beyond sales and rentals to include equipment calibrations, repairs, and training. MFE offers Advanced UAV Piloting courses, Infrared Thermography Courses, MFL Inspection, Spot 101 Introductory courses, and specialized advanced Elios indoor drone inspection training.",
    ],
  },
  {
    id: 23,
    contacts: [
      {
        company: "MGI Construction Corp.",
        name: "Estimating",
        phone: "416-675-2280",
        fax: "",
        email: " estimating@mgicorp.ca",
        address: "11 Dansk Court",
        city: "Etobicoke",
        province: "Ontario",
        postal: "M9W 5N6",
        website: "www.mgicorp.ca",
        latitude: 43.743397685676854,
        longitude: -79.61564352975759,
      },
      {
        company: "MGI Construction Corp.",
        name: "Estimating - Grey County",
        phone: "416-675-2280",
        fax: "",
        email: " estimating@mgicorp.ca",
        address: "78 Stewart Street",
        city: "Meaford",
        province: "Ontario",
        postal: "N4L 1J9",
        website: "www.mgicorp.ca",
        latitude: 43.44496124029375,
        longitude: -79.68286722990692,
      },
    ],
    logo: "/images/logos/general/MGI-Contruction-Corp.png",
    profile: [
      "MGI Construction Corporation specializes in four main areas and aims to provide an integrative service to our clients from start to finish. Our vision is to cultivate, develop, and restate the land we share and the communities built upon it. Join us in Constructing History.",
    ],
  },

  {
    id: 25,
    contacts: [
      {
        company: "Paracel Laboratories Ltd.",
        name: "Shawn Howard",
        phone: "613-731-9577",
        fax: "",
        email: "showard@paracellabs.com",
        address: "300-2319 St. Laurent Blvd",
        city: "Ottawa",
        province: "Ontario",
        postal: "K1G 4J8",
        website: "www.paracellabs.com",
        latitude: 45.45037987816157,
        longitude: -75.65337429819738,
      },
    ],
    logo: "/images/logos/general/paracel.png",
    profile: [
      "Paracel Laboratories Ltd. is a 100 percent Canadian owned, full-service Environmental/IAQ laboratory. With lab locations in Ottawa, Nepean and Mississauga, we provide a wide range of testing services including asbestos, mould, heavy metals, and PCBs in building materials and indoor air. Paracel is accredited by NVLAP and CALA to ISO 17025:2005.",
    ],
  },
  {
    id: 26,
    contacts: [
      {
        company: "Safety Express",
        name: "Greg Gilbert",
        phone: "613-526-5800 x2203",
        fax: "",
        email: "ggilbert@safetyexpress.com",
        address: "1290 Old Innes Rd., #707",
        city: "Ottawa",
        province: "Ontario",
        postal: "K1B 5M6",
        website: "www.safetyexpress.com",
        latitude: 45.409723765603786,
        longitude: -75.61503464425358,
      },
    ],
    logo: "/images/logos/general/safety-express.png",
    profile: [
      "Safety Express has been servicing the construction, restoration, abatement and general safety industries with safety and remedial products for over 27 years. IICRC & OH&S Training, equipment repairs, rentals, respiratory fit testing and DOP testing available at all 6 locations 24/7.",
    ],
  },
  {
    id: 27,
    contacts: [
      {
        company: "Schouten Environmental Inc.",
        name: "Brant Nicholson",
        phone: "519-577-8989",
        fax: "",
        email: "brant@schouten.ca",
        address: "#16 140 Mcgovern Dr.",
        city: "Cambridge",
        province: "Ontario",
        postal: "N3H 4R7",
        website: "www.schouten.ca",
        latitude: 43.409879418350094,
        longitude: -80.33278361457936,
      },
    ],
    logo: "/images/logos/general/shouten.png",
    profile: [
      "Asbestos abatement and Mould remediation specialists servicing  residential, commercial and industrial locations throughout  Kitchener – Waterloo, London Sarnia regions. Schouten Environmental additionally offers selective demolition services to better serve customer and client needs.",
    ],
  },
  {
    id: 28,
    contacts: [
      {
        company: "Sporometrics",
        name: "Kristine White",
        phone: "416-516-1660",
        fax: "416-516-1670",
        email: "kwhite@sporometrics.com",
        address: "219 Dufferin Street, Unit 20C",
        city: "Toronto",
        province: "Ontario",
        postal: "M6K 1Y9",
        website: "www.sporometrics.com",
        latitude: 43.63692228399214,
        longitude: -79.42623842981095,
      },
    ],
    logo: "/images/logos/general/spometrics.png",
    profile: [
      "Sporometrics is an AIHA-accredited laboratory offering a full suite of microscopic, physiological and molecular genetic analyses for mould, bacteria and viruses. With the top of the line equipment and the most qualified personnel in the business we are the laboratory for your spore traps, bulk/tape lifts, bacterial samples or any other environmental samples.",
    ],
  },

  {
    id: 29.1,
    contacts: [
      {
        company: "TCI Titan Contracting Inc. – Titan Environmental",
        name: "Art Ussoletti",
        phone: "519-977-1125",
        fax: "519-977-0352",
        email: "art@tciwindsor.com",
        address: "2489 Seminole Street",
        city: "Windsor",
        province: "Ontario",
        postal: "N8Y 1X2",
        website: "www.tciwindsor.com",
        latitude: 42.31104761515093,
        longitude: -82.99715180187277,
      },
    ],
    logo: "/images/logos/general/titan.png",
    profile: [
      "Titan Environmental specializes in the removal of hazardous waste materials from commercial, institutional, and residential properties.",
      "With the latest technology and advanced equipment coupled with exceptionally qualified staff, Titan Environmental is equipped to provide quality environmental remediation services while staying in compliance with industry standards, customs, and regulations. ",
      "These features and services include: thoroughly trained and fully insured asbestos and mould remediation technicians; complete worksite preparation, cleaning, and safe disposal of asbestos; Type 1, Type 2 and Type 3; removal of lead, mould, mercury, PCBs, Ozone depleting substances; and monitoring of air quality.",
    ],
  },
  {
    id: 30,
    contacts: [
      {
        company: "Thomas James Environmental Inc.",
        name: "Robert Dewar",
        phone: "647-991-9246",
        fax: "",
        email: "rdewar@thomasjames.ca",
        address: "6A – 170 The Donway West, Suite 415",
        city: "Toronto",
        province: "Ontario",
        postal: "M3C 2E8",
        website: "www.thomasjames.ca",
        latitude: 43.735480981960734,
        longitude: -79.34801482976151,
      },
    ],
    logo: "",
    profile: [],
  },
  {
    id: 31,
    contacts: [
      {
        company: "True-Care Restoration Inc.",
        name: "Geoff Zinner",
        phone: "905-492-3510",
        fax: "",
        email: "geoff@true-care.ca",
        address: "1472 Thorton Rd. N., Unit 19",
        city: "Oshawa",
        province: "Ontario",
        postal: "L1L 0P3",
        website: "www.true-care.ca",
        latitude: 43.92821327385314,
        longitude: -78.91188759897445,
      },
    ],
    logo: "/images/logos/general/true-care.png",
    profile: [
      " True-Care Restoration is a multi disciplinary organization. Our business is primarily about providing repair/maintenance services to Municipal and Privately Owned facility/property management organizations and an added value service of Emergency and Speciality Services.",

      " Our commitment to continuous improvement is what drives us to improve our systems and processes to ensure they are scalable to not only meet our clients current needs, but to anticipate their future needs.",

      " Our mission statement, customer charter and values are fundamental on how we offer services to our customers.",
    ],
  },
];
