import React from "react";
import "../MobileNavList/MobileNavList.scss";
import { Link } from "react-router-dom";

function MobileNavList({ setMenuActive }) {
  return (
    <div className="mobile">
      <div className="mobile__dropdown">
        <ul className="mobile__list">
          <Link
            to="/"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Home</li>
          </Link>
          <Link
            to="/about"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Who We Are</li>
          </Link>
          <Link
            to="/members"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Members</li>
          </Link>
          <Link
            to="/committee"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Committees</li>
          </Link>
          <Link
            to="/guidelines"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Guidelines</li>
          </Link>
          <Link
            to="/documents"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Documents</li>
          </Link>
          <Link
            to="/join"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">Join</li>
          </Link>
          <Link
            to="/news-events"
            onClick={() => setMenuActive(false)}
            className="mobile__link"
          >
            <li className="mobile__item">News & Events</li>
          </Link>
          <ul className="mobile__list mobile__list--board">
            <Link
              to="/board-of-directors"
              onClick={() => setMenuActive(false)}
              className="mobile__link"
            >
              <li className="mobile__item">Board of Directors</li>
            </Link>
            <Link
              to="/board-meeting-dates"
              onClick={() => setMenuActive(false)}
              className="mobile__link"
            >
              <li className="mobile__item">Board Meeting Dates</li>
            </Link>
            <Link
              to="/board-meeting-minutes"
              onClick={() => setMenuActive(false)}
              className="mobile__link"
            >
              <li className="mobile__item">Board Meeting Minutes</li>
            </Link>
          </ul>{" "}
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/faq"
          >
            <li className="mobile__item">FAQs</li>
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/covid-19"
          >
            <li className="mobile__item">Covid-19</li>
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/radon"
          >
            <li className="mobile__item">Radon</li>
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/drug-opioid"
          >
            <li className="mobile__item">Drug/Opioid</li>
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/job-postings"
          >
            <li className="mobile__item">Job Postings</li>{" "}
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/photos"
          >
            <li className="mobile__item">Photos</li>
          </Link>
          <Link
            className="mobile__link"
            onClick={() => {
              setMenuActive(false);
            }}
            to="/contact-us"
          >
            <li className="mobile__item">Contact Us</li>
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default MobileNavList;
