import React from "react";
import Hero from "../../components/Hero/Hero";
import "../../pages/Committee/Committee.scss";

function Committee() {
  return (
    <>
      <Hero title={"Committees"} />

      <div className="committee">
        <div className="committee__section">
          <div>
            <span className="committee__title--bold">ASBESTOS</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Asbestos Committee will be co-chaired by David Bremner,
              Environmental Response Team (ERT) and Martin Ling (Stantec
              Consulting Ltd.) and the mandates, directives, and updates from
              the EACC Board of Directors will be communicated during the
              committee meetings, with an agenda provided in advance of the
              meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">CONSULTANTS</span>

            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Consultants Committee will be chaired by Martin Ling, Stantec
              Consulting Ltd. and the mandates, directives, and updates from the
              EACC Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">CONTRACTORS</span>

            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Contractor Committee will be chaired by Norine Jones, Power
              Environmental/Power Vac Services and the mandates, directives, and
              the mandates, directives, and updates from the EACC Board of
              Directors will be communicated during the committee meetings, with
              an agenda provided in advance of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">LEAD</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Lead Committee will be chaired by Bruce Decker, ECOH
              Management Inc. and the mandates, directives, and updates from the
              EACC Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>

          {/* Designated Substances and Hazardous Materials Assessment Committee */}
          <div>
            <span className="committee__title--bold">
              DESIGNATED SUBSTANCES AND HAZARDOUS MATERIALS ASSESSMENT
            </span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Designated Substances and Hazardous Materials Assessment
              Committee will be chaired by Steve Fulford, Fulford Roy and
              Associates Consulting Inc. The mandates, directives, and updates
              from the EACC Board of Directors will be communicated during the
              committee meetings, with an agenda provided in advance of the
              meetings.
            </p>
          </div>

          {/* DOP Committee */}
          <div>
            <span className="committee__title--bold">DOP</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The DOP Committee will be chaired by Glenn Smith, Safetech
              Environmental Limited. The mandates, directives, and updates from
              the EACC Board of Directors will be communicated during the
              committee meetings, with an agenda provided in advance of the
              meetings.
            </p>
          </div>

          {/* Indoor Air Quality Committee */}
          <div>
            <span className="committee__title--bold">INDOOR AIR QUALITY</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Indoor Air Quality Committee will be co-chaired by Rob
              Robinson, Stantec. The mandates, directives, and updates from the
              EACC Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>

          {/* Infection Control Committee */}
          <div>
            <span className="committee__title--bold">INFECTION CONTROL</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Infection Control Committee will be chaired by Pete Ferrante,
              Ferro Canada. The mandates, directives, and updates from the EACC
              Board of Directors will be communicated during the committee
              meetings, with an agenda provided in advance of the meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">MARKETING & PROGRAMS</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Marketing & Programs Committee will be chaired by Catherine
              Mills, ECOH Management Inc. The mandates, directives, and updates
              from the EACC Board of Directors will be communicated during the
              committee meetings, with an agenda provided in advance of the
              meetings.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">MEMBERSHIP</span>
            <h3 className="committee__title">
              The Membership Committee focuses on engaging industry
              professionals and expanding the reach of the EACC through
              membership growth and retention efforts.
            </h3>
            <p className="committee__body">
              The Membership Committee will be chaired by Glenn Smith, Safetech
              Environmental Limited. The committee will oversee member benefits,
              recruitment initiatives, and networking opportunities to
              strengthen the association's impact within the industry.
            </p>
          </div>

          <div>
            <span className="committee__title--bold">MOULD</span>
            <h3 className="committee__title">
              The Mould Committee is dedicated to improving mould remediation
              strategies and ensuring compliance with health and safety
              regulations.
            </h3>
            <p className="committee__body">
              The Mould Committee will be chaired by Glenn Smith, Safetech
              Environmental Limited. It will provide guidance on identifying,
              assessing, and removing mould contaminants in a safe and effective
              manner, while also advocating for industry standards.
            </p>
          </div>
          <div>
            <span className="committee__title--bold">OPIOID REMEDIATION</span>
            <h3 className="committee__title">
              The Opioid Remediation Committee aims to establish safety
              protocols and best practices for the decontamination of spaces
              affected by opioid-related hazards.
            </h3>
            <p className="committee__body">
              The Opioid Remediation Committee will be co-chaired by Suzanne
              Wilde, Stantec. The committee will develop training, guidelines,
              and response plans to support industry professionals in mitigating
              risks associated with opioid contamination.
            </p>
          </div>

          <div>
            <span className="committee__title--bold">RADON</span>
            <h3 className="committee__title">
              The Radon Committee is committed to raising awareness and
              promoting best practices for radon mitigation and testing in
              various environments.
            </h3>
            <p className="committee__body">
              The Radon Committee will be co-chaired by Scott Cryer, Pinchin.
              This committee will work closely with regulatory bodies and
              industry professionals to ensure compliance with radon safety
              standards and to disseminate updated guidelines and research
              findings.
            </p>
          </div>

          <div>
            <span className="committee__title--bold">TRAINING</span>
            <h3 className="committee__title">
              The Training Committee focuses on developing educational resources
              and training programs to enhance industry knowledge and compliance
              with safety standards.
            </h3>
            <p className="committee__body">
              The Training Committee will be chaired by Jeff Lainsbury, Reveal
              Environmental Inc. It will work towards providing structured
              learning opportunities, certifications, and workshops to ensure
              industry professionals stay informed on the latest practices and
              regulatory requirements.
            </p>
          </div>

          <div>
            <span className="committee__title--bold">VERMICULITE</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Vermiculite Committee will be chaired by Steve Fulford,
              Fulford Roy and Associates Consulting Inc. The mandates,
              directives, and updates from the EACC Board of Directors will be
              communicated during the committee meetings, with an agenda
              provided in advance of the meetings.
            </p>
          </div>

          <div>
            <span className="committee__title--bold">WORKER HYGIENE</span>
            <h3 className="committee__title">
              The goal of the committee will be to support the EACC Board of
              Directors and the Association in reviewing applicable mandates and
              directives from the EACC Board of Directors, with the intended
              focus of the betterment of the abatement and hazardous materials
              industry.
            </h3>
            <p className="committee__body">
              The Worker Hygiene Committee will be chaired by John Kocjan, Ecoh
              Environmental Consulting Occupational Health. The mandates,
              directives, and updates from the EACC Board of Directors will be
              communicated during the committee meetings, with an agenda
              provided in advance of the meetings.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Committee;
