import "./News.scss";
import Calender from "../../../assets/icons/calender.png";
import Quote from "../../../assets/icons/quote.png";

function News() {
  return (
    <div className="news">
      <div className="news__card news__card--left">
        <img className="news__image" src={Calender} alt="Calender Icon" />
        {/* <h3 className="news__title">Upcoming Events</h3> */}
        <h3 className="news__title">Past Events</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">EACC Annual Golf Tournament</p>
          <p className="news__text news__text--padding">
            The Environmental Abatement Council of Canada hosted their annual
            golf tournament on September 18th, 2024 at Wooden Sticks Golf Club,
            Uxbridge, ON.
            <br />
            <br />
            All attendees received a pair of Adidas Golf Shoes, and they were
            treated to lunch before Golf and an amazing dinner after golf, and
            an opportunity to catch up with industry colleagues.
            <br />
            <br />
            {/* <span>Location:</span> Wooden Sticks Golf Club, Uxbridge */}
            Congratulations to all the winners and thanks to everyone who
            attended.
          </p>

          <a
            className="news__event-link"
            href={"/photos/2024-eacc-golf-tournament"}
          >
            See More
          </a>
        </div>
      </div>
      <div className="news__card">
        <img className="news__image" src={Quote} alt="Speech Bubble Icon" />
        <h3 className="news__title">News & Announcements</h3>
        <div className="news__text-wrapper">
          <p className="news__event-title">
            News: Steve Fulford as our new Executive Director!
          </p>
          <p className="news__text news__text--padding">
            This role is an important development in our ongoing efforts to
            strengthen the EACC and elevate our organization to new heights! As
            many of you know, Steve is no stranger to the EACC. Over the years,
            he has been a steadfast leader, serving in various capacities on the
            Board, including most recently as our Past President. His decades of
            experience in the environmental abatement industry and his deep
            passion for our mission make him uniquely qualified to take on this
            new role.
          </p>
          <a
            className="subscribe__button"
            href="https://www.eaccan.com/news-events"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
}
export default News;
