import Hero from "../../components/Hero/Hero";
import "./EventsPage.scss";

import Award from "../../assets/EACO-award.jpeg";
import { Link } from "react-router-dom";

function EventsPage() {
  return (
    <>
      <Hero title="News & Events" />
      <div className="events">
        <div className="events__card events__card--padding">
          {/* <h3 className="events__heading">
            Stay tuned for upcoming event details.
          </h3> */}
          <h3 className="events__heading">EACC Annual Golf Tournament</h3>
          <p>
            The Environmental Abatement Council of Canada hosted their annual
            golf tournament on September 18th, 2024 at Wooden Sticks Golf Club,
            Uxbridge, ON.
            <br />
            <br />
            All attendees received a pair of Adidas Golf Shoes, and they were
            treated to lunch before Golf and an amazing dinner after golf, and
            an opportunity to catch up with industry colleagues.
            <br />
            <br />
            {/* <span>Location:</span> Wooden Sticks Golf Club, Uxbridge */}
            Congratulations to all the winners and thanks to everyone who
            attended.
          </p>

          <a
            href={"/photos/2024-eacc-golf-tournament"}
            className="events__button"
            target="_blank"
            rel="noreferrer"
          >
            See More
          </a>
          <div className="events__divider"></div>

          {/* <a
            href="http://events.r20.constantcontact.com/register/event?oeidk=a07ejwc5uxn20ce7f77&llr=ipefsquab"
            className="events__button"
            target="_blank"
          >
           
            Registration
          </a> */}
        </div>
        <div className="events__card">
          <h3 className="events__heading events__heading--padding">News</h3>
          {/* <h5 className="events__subheading">
            Press Release <br />
            June 10, 2021
          </h5> */}

          {/* <h5 className="events__subheading">
            EACO is now EACC, the Environmental Abatement Council of Canada.
          </h5> */}

          {/* <p className="events__text events__text--padding">
            EACO President, John Kocjan, launches the new Environmental
            Abatement Council of Canada at its Annual General Meeting.
          </p> */}

          {/* <Link className="events__link" to="/press-release">
            Read the Press Release here.
          </Link> */}

          <div className="events__divider"></div>
          <p className="events__text">
            {/* <img className="events__image" src={Award} alt="EACC Award" /> */}
            We're pleased to announce Steve Fulford as our new Executive
            Director of the EACC! <br></br>
            <br></br> This role is an important development in our ongoing
            efforts to strengthen the EACC and elevate our organization to new
            heights! As many of you know, Steve is no stranger to the EACC. Over
            the years, he has been a steadfast leader, serving in various
            capacities on the Board, including most recently as our Past
            President. His decades of experience in the environmental abatement
            industry and his deep passion for our mission make him uniquely
            qualified to take on this new role.
            <br></br> <br></br> The Executive Director position is a newly
            created, part-time role designed to support our strategic growth,
            communication, and operational effectiveness. Steve’s leadership
            will drive our initiatives, coordinate programs, and enhance member
            engagement. He’ll also lead efforts to expand our presence
            nationally, starting with onboarding the Ottawa/Eastern Ontario
            Chapter and building relationships with government agencies to
            position the EACC as a thought leader in the industry.<br></br>
            <br></br> We're confident that under Steve’s guidance, the EACC will
            continue to thrive and grow, benefiting our members and the broader
            environmental abatement community. <br></br> <br></br> Please join
            us in welcoming Steve back in this exciting new capacity!
          </p>
        </div>
      </div>
    </>
  );
}
export default EventsPage;
