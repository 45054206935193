import { useState } from "react";
import SecondaryNav from "../SecondaryNav/SecondaryNav";
import "./Nav.scss";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../../assets/EACC-Logo.png";
import down from "../../../assets/icons/down.png";
import burgerMenu from "../../../assets/icons/burger.svg";
import MobileNavList from "../MobileNavList/MobileNavList";

function Nav() {
  const [menuActive, setMenuActive] = useState(false);
  const [boardActive, setBoardActive] = useState(false);
  const [shrinkActive, setShrinkActive] = useState(false);

  let location = useLocation();

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (window.scrollY > 90) {
      setShrinkActive(true);
    }
    if (window.scrollY < 60) {
      setShrinkActive(false);
    }
  }

  const checkLocation = (page) => {
    if (page === location.pathname) {
      return "nav__list-item--active";
    }
  };

  return (
    <div className="nav">
      <SecondaryNav />
      <div className="nav__main">
        <Link to="/" className="nav__logo-container">
          <img
            className={`nav__logo ${shrinkActive ? "nav__logo--shrink" : ""}`}
            id="logo"
            src={Logo}
            alt="Logo"
          />
        </Link>
        <ul className={`nav__list ${shrinkActive ? "nav__list--shrink" : ""}`}>
          <Link className="nav__link" to="/">
            <li className={`nav__list-item ${checkLocation("/")}`}>Home</li>
          </Link>
          <Link className="nav__link" to="/about">
            <li className="nav__list-item">Who We Are</li>
          </Link>
          <Link className="nav__link" to="/members">
            <li className="nav__list-item">Members</li>
          </Link>
          <Link className="nav__link" to="/committee">
            <li className="nav__list-item">Committees</li>
          </Link>
          <Link className="nav__link" to="/guidelines">
            <li className="nav__list-item">Guidelines</li>
          </Link>
          <Link className="nav__link" to="/documents">
            <li className="nav__list-item">Documents</li>
          </Link>
          <Link className="nav__link" to="/join">
            <li className="nav__list-item">Join</li>
          </Link>
          <Link className="nav__link" to="/news-events">
            <li className="nav__list-item">News & Events</li>
          </Link>
          <div
            className="nav__board"
            onMouseOver={() => {
              setBoardActive(true);
            }}
            onMouseLeave={() => {
              setBoardActive(false);
            }}
          >
            <li className="nav__list-item nav__list-item--board">
              Board{" "}
              <img src={down} className="nav__arrow" alt="Down arrow icon" />
              {boardActive && (
                <div
                  id="board"
                  className={`nav__board-nav ${
                    shrinkActive ? "nav__board-nav--shrink" : ""
                  }`}
                >
                  <ul className="nav__board-list">
                    <Link className="nav__link" to="/board-of-directors">
                      <li className="nav__list-item">Board of Directors</li>
                    </Link>
                    <Link className="nav__link" to="/board-meeting-dates">
                      <li className="nav__list-item">Board Meeting Dates</li>
                    </Link>
                    <Link className="nav__link" to="/board-meeting-minutes">
                      <li className="nav__list-item">Board Meeting Minutes</li>
                    </Link>
                  </ul>
                </div>
              )}
            </li>
          </div>

          <Link className="nav__link" to="/faq">
            <li className="nav__list-item">FAQs</li>
          </Link>
        </ul>
        <img
          onClick={() => {
            setMenuActive(!menuActive);
          }}
          className="nav__burger"
          src={burgerMenu}
          alt="burger menu"
        ></img>
      </div>

      {menuActive && <MobileNavList setMenuActive={setMenuActive} />}
    </div>
  );
}
export default Nav;
